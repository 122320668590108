









































import Vue, { PropType } from 'vue'

// components
import OrderDetailsCard from '@/components/orders/OrderDetailsCard.vue'

export default Vue.extend({
  components: { OrderDetailsCard },
  props: {
    loading: Boolean,
    errors: Array as PropType<Error[]>,
    dialogQuery: {
      type: Object as PropType<unknown | undefined>,
      required: false,
    },
  },
  data: () => ({}),
  computed: {
    dialog(): boolean {
      return !!this.dialogQuery
    },
  },
  methods: {
    closeDialog(): void {
      this.$emit('update:dialog-query', undefined)
    },
  },
})
