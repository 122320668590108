
















import Vue, { PropType } from 'vue'

import deepFreeze, { DeepReadonly } from 'deep-freeze'
import { ApexOptions } from 'apexcharts'

// event bus
import MessageBus from '@/message-bus'

// components
import JChartWrapper from '@/components/controls/JChartWrapper.vue'

// types
import { ID, OrderDetail } from '@/types'
import { isArray, isNumber } from '@/utils/type-helpers'
import log from '@/log'

type Query = {
  [key: string]: number | string
}
type FinderFunction = (id: ID) => string

type Prop = string | number
type Result = OrderDetail
type Fetcher<In, Out> = (...data: In[]) => Promise<Out>

const transformDate = (dateStr: string) => {
  const [y, m, d] = dateStr.split('-').map(n => Number(n))
  return Date.UTC(y, m - 1, d)
}

const getX = (field: keyof Result | (keyof Result)[]) => (d: Result) =>
  (
    (Array.isArray(field) ? field.map(f => d[f]).join('-') : d[field]) ||
    'unknown'
  ).toString()

export default Vue.extend({
  components: {
    JChartWrapper,
  },
  props: {
    fetcher: {
      type: Function as PropType<Fetcher<Prop, Result[]>>,
      required: true,
    },
    fetchProps: {
      type: Array as PropType<Prop[]>,
      required: true,
    },

    title: String,
    height: [String, Number],

    rowField: {
      type: [String, Array] as PropType<keyof Result | (keyof Result)[]>,
      default: 'orderId',
    },
    rowLabel: String,
    hideRowLabel: Boolean,
    dateField: {
      type: String as PropType<keyof Result>,
      default: 'receivedDate',
    },
    dateLabel: String,
    hideDateLabel: Boolean,
    categoryField: {
      type: String as PropType<keyof Result>,
      required: true,
    },
    categoryFinder: {
      type: Function as PropType<FinderFunction>,
      required: true,
    },
  },
  data: () => ({
    result: undefined as DeepReadonly<Result[]> | undefined,

    isFetching: 0,
    errors: [] as Error[],

    dialogQuery: undefined as Query | undefined,
  }),
  watch: {
    fetchProps: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value === undefined) {
          this.errors.push(Error('no data'))
        } else {
          this.fetchData()
        }
      },
    },
  },
  computed: {
    today(): string {
      return new Date().toISOString().split('T')[0]
    },
    rowLabelValue(): string | undefined {
      if (this.hideRowLabel) return undefined
      return (
        this.rowLabel ||
        (Array.isArray(this.rowField)
          ? this.rowField.join(' + ')
          : this.rowField)
      )
    },
    dateLabelValue(): string | undefined {
      if (this.hideDateLabel) return undefined
      return this.dateLabel || this.dateField
    },
    seriesIds(): number[] {
      if (!this.result) return []
      return [
        ...new Set(
          this.result.map(r => r[this.categoryField as keyof Result] as number)
        ),
      ].sort((a, b) => a - b)
    },
    series(): {
      name: string
      data: { x: string; y: number[]; data: Result }[]
    }[] {
      if (!this.result) return []
      const result = this.result
      return this.seriesIds.map(id => ({
        name: this.categoryFinder(id),
        data: result
          .filter(r => r[this.categoryField as keyof Result] === id)
          .map(r => {
            const y1 = transformDate(
              (r[this.dateField as keyof Result] as string) ||
                r.postedDate ||
                this.today
            ) // TODO: what if dateField is null?
            const y2 = transformDate(r.postedDate || this.today)

            return {
              x: getX(this.rowField as keyof Result | (keyof Result)[])(r),
              y: [y1, y2],
              data: r,
            }
          })
          .filter(r => r.y[1] > r.y[0]),
      }))
    },
    chartHeight(): string | number {
      // eslint-disable-next-line prettier/prettier
      return (
        this.height ||
        [
          ...new Set(
            this.series.map(s => s.data.map((d: { x: string }) => d.x)).flat()
          ),
        ].length *
          this.series.length *
          12 +
          16
      )
    },
    chartOptions(): ApexOptions {
      return {
        chart: {
          type: 'rangeBar',
          animations: {
            enabled: false,
          },
          events: {
            click: (event, chartContext, options) => {
              if (
                options.seriesIndex >= 0 &&
                options.dataPointIndex >= 0 &&
                this.result
              ) {
                const fullWhere: Query = {}

                fullWhere[this.categoryField] = this.seriesIds[
                  options.seriesIndex
                ]

                const dataPoint = this.series[options.seriesIndex].data[
                  options.dataPointIndex
                ]

                const rowFields = Array.isArray(this.rowField)
                  ? this.rowField
                  : [this.rowField as keyof OrderDetail]

                if (dataPoint) {
                  if (isNumber(dataPoint)) {
                    // do nothing
                  } else if (isArray(dataPoint)) {
                    // do nothing
                  } else {
                    rowFields.forEach(rowField => {
                      const v = dataPoint.data[rowField]
                      if (v && (typeof v === 'string' || typeof v === 'number'))
                        fullWhere[rowField] = v
                    })
                    this.dialogQuery = fullWhere
                  }
                }
              }
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '100%',
          },
        },
        title: {
          text: this.title,
          align: 'left',
        },
        // yaxis or xaxis (for horizontal)
        xaxis: {
          type: 'datetime',
          title: {
            text: this.dateLabelValue,
          },
        },
        dataLabels: {},
        tooltip: {
          y: {},
        },
      }
    },
  },
  methods: {
    fetchData() {
      this.isFetching++
      this.errors = []

      this.fetcher(...this.fetchProps)
        .then(result => {
          log.info(result)
          this.result = deepFreeze(result)
        })
        .catch(err => {
          MessageBus.error(err)
          this.errors.push(err)
        })
        .finally(() => {
          this.isFetching--
        })
    },
  },
})
