











import Vue from 'vue'

// components
import RangeBarChart from '@/components/charts/RangeBarChart.vue'

export default Vue.extend({
  metaInfo: {
    title: 'Sales Timeline Charts - Dashboard',
  },
  components: {
    RangeBarChart,
  },
  props: {
    period: {
      type: Number,
      default: new Date().getFullYear(),
    },
  },
  data: () => ({}),
  computed: {
    fetcher() {
      return this.$api.charts.salesTimeline.get
    },
    fetchProps() {
      return [this.period]
    },
    categoryField() {
      return 'productCategoryGroupId'
    },
  },
  methods: {
    categoryFinder(id: number): string {
      return this.$store.getters.getProductCategoryGroupById(id).abbr
    },
  },
})
